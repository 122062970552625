import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import '../layout/styles/layout.css';
import Navigation from './Navigation';


function Header() {
  return (
    <div className="bgded overlay" style={{backgroundImage: 'url(' + require('../images/background_cover.JPG') + ')'}}>
      <div className="wrapper row0">
        <div id="topbar" className="hoc clear">
          <div className="fl_left">
            <ul className="nospace">
              <li><i className="fas fa-phone"></i> +1 (413) 737 3197</li>
              <li><i className="far fa-envelope"></i> precisionmfg2@gmail.com</li>
              <li><i className="fas fa-fax"></i> +1 (413) 737 3198</li>
            </ul>
          </div>
          <div className="fl_right">
          </div>
        </div>
      </div>
      <div className="wrapper row1">
        <header id="header" className="hoc clear">
          <div id="logo" className="fl_left">
            <h1><a><i className="fas fa-industry"></i> Precision Manufacturing Ind., LLC</a></h1>
          </div>
          <nav id="mainav" className="fl_right">
            <Navigation />
          </nav>
        </header>
      </div>
      <div id="pageintro" className="hoc clear">
        <article className="center">
          <h3 className="heading underline">Welcome!</h3>
          <h2>Precision Manufacturing is a Family-Owned Machine Shop Specializing in 3 & 4 Axis CNC Machining, Small & Large Lot Production Runs, Turning, and Part Design & Assembly.</h2>
          <footer><NavLink to="/about"><a className="btn">Learn About Us</a></NavLink></footer>
        </article>
      </div>
    </div>
  );
}

export default Header;