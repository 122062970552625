import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';


const useVisibility = (ref) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the container
        threshold: 0.1 // Trigger if 10% of the element is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return isVisible;
};

const Navigation = () => {
    const targetRef = useRef(null);
    const isVisible = useVisibility(targetRef);

    const nav = useNavigate();
  
    const handleChange = (event) => {
    nav(event.target.value);
    };

    return (
    <div>
        <ul className="clear" ref={targetRef}>
            <li><NavLink to="/"><i className="fas fa-home"></i> Home</NavLink></li>
            <li><a href="#"><i className="fas fa-shopping-basket"></i> Store</a></li>
            <li><NavLink to="/gallery"><i className="fas fa-images"></i> Gallery</NavLink></li>
            <li><NavLink to="/about"><i className="fas fa-info-circle"></i> About Us</NavLink></li>
            <li><NavLink to="/contact"><i className="fas fa-envelope"></i> Contact Us </NavLink></li>
        </ul>
      {!isVisible && (
        <form onChange={handleChange}>
            <select>
                <option value="">Navigate to...</option>
                <option value="/">Home</option>
                <option value="#">Store</option>
                <option value="/gallery">Gallery</option>
                <option value="/about">About Us</option>
                <option value="/contact">Contact Us</option>
            </select>
        </form>
      )}
    </div>
  );
};

export default Navigation;