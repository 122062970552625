import React from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import emailjs from 'emailjs-com';
import '../layout/styles/contact.css';

function LandingPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would handle the form submission, e.g., sending an email
    console.log('Email:', email);
    console.log('Message:', message);

    // Use Emailjs to send the email
    // Replace the placeholders with your own Emailjs credentials and template ID
    emailjs.send('service_bkefn5d', 'template_pf7t05o', {
      email: email,
      message: email + ': ' + message
    }, 'wp8-V6wzAlIvJAvMY')
      .then((response) => {
        console.log('Email sent successfully:', response.text);
        // Add any additional logic here, e.g., showing a success message to the user
        alert('Email successfully sent!');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // Add any error handling logic here, e.g., showing an error message to the user
        alert('Error sending email. Please try again later.');
      });
  };
  return (
    <div className="LandingPage">
      <div class="wrapper row2" style={{backgroundColor: "silver"}}>
        <article class="hoc container clear"> 
          <div class="center btmspace-80">
            <h4 class="heading underline font-x2" style={{color: "black"}}>What We Do</h4>
          </div>
          <ul class="center nospace group figures btmspace-80">
            <li class="one_half first">
              <figure><img src={require("../images/cad.jpg")} alt=""/>
                <figcaption><em>01</em> <a>Product Design</a></figcaption>
              </figure>
            </li>
            <li class="one_half">
              <figure><img src={require("../images/reverse.jpeg")} alt=""/>
                <figcaption><em>02</em> <a>Reverse Engineering</a></figcaption>
              </figure>
            </li>
          </ul>
          <ul class="center nospace group figures btmspace-80">
            <li class="one_half first">
              <figure><img src={require("../images/cnc.jpg")} alt=""/>
                <figcaption><em>03</em> <a>3 & 4 Axis CNC Machining</a></figcaption>
              </figure>
            </li>
            <li class="one_half">
              <figure><img src={require("../images/lot.jpeg")} alt=""/>
                <figcaption><em>04</em> <a>Large Lot Production</a></figcaption>
              </figure>
            </li>
          </ul>
        </article>
      </div>
      <div class="wrapper row2">
        <article class="hoc container clear"> 
          <div class="center btmspace-80">
            <h6 class="heading underline font-x2">Take a Tour of the Shop</h6>
          </div>
          <ul class="center nospace group figures btmspace-80">
            <li class="one_third first">
              <figure><img src={require("../images/shop_photo1.JPG")} alt=""/>
                <figcaption><em>01</em> <a>A View of the Main Floor</a></figcaption>
              </figure>
            </li>
            <li class="one_third">
              <figure><img src={require("../images/shop_photo2.JPG")} alt=""/>
                <figcaption><em>02</em> <a>Main Floor From Above</a></figcaption>
              </figure>
            </li>
            <li class="one_third">
              <figure><img src={require("../images/shop_photo4.JPG")} alt=""/>
                <figcaption><em>03</em> <a>Inspection Room</a></figcaption>
              </figure>
            </li>
          </ul>
          <footer class="center"><NavLink to="/gallery"><a class="btn">View Full Gallery</a></NavLink></footer>
        </article>
      </div>
      <div class="wrapper row2" style={{backgroundColor: "silver"}}>
        <section class="hoc container clear"> 
          <div class="center btmspace-80">
            <h6 class="heading underline font-x2" style={{color: "black"}}>View Our Work</h6>
          </div>
          <ul class="center nospace group figures latest btmspace-80">
            <li class="one_third first">
              <figure><img src={require("../images/part1.JPG")} alt=""/>
                <figcaption><em>01</em></figcaption>
              </figure>
            </li>
            <li class="one_third">
              <figure><img src={require("../images/part2.JPG")} alt=""/>
                <figcaption><em>02</em></figcaption>
              </figure>
            </li>
            <li class="one_third">
              <figure><img src={require("../images/part3.JPG")} alt=""/>
                <figcaption><em>03</em></figcaption>
              </figure>
            </li>
          </ul>
          <footer class="center"><NavLink to="/gallery"><a class="btn">View Full Gallery</a></NavLink></footer>
        </section>
      </div>
      <div class="wrapper row2">
        <article class="hoc container clear"> 
          <div class="center btmspace-80">
            <h6 class="heading underline font-x2">Get a Quote</h6>
          </div>
          <div class="center contact-form">
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <input className="btmspace-15"
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)} 
                placeholder="Name"
                required 
                style={{ width: '50%' }}
              />
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                style={{ width: '50%' }}
              />
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
                required
                style={{ width: '50%'}}
              />
              <div style={{ marginTop: '10px' }}></div>
              <button type="submit">Send Email</button>
            </form>
          </div>
        </article>
      </div>
    </div>
  );
}

export default LandingPage;