import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.css';
import '../layout/styles/layout.css';
import { useState } from 'react';
import emailjs from 'emailjs-com';

function Footer(){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you would handle the form submission, e.g., sending an email
        console.log('Email:', email);
        console.log('Message:', message);

        // Use Emailjs to send the email
        // Replace the placeholders with your own Emailjs credentials and template ID
        emailjs.send('service_bkefn5d', 'template_pf7t05o', {
        email: email,
        message: name + ": " + email + ': ' + message
        }, 'wp8-V6wzAlIvJAvMY')
        .then((response) => {
            console.log('Email sent successfully:', response.text);
            // Add any additional logic here, e.g., showing a success message to the user
            alert('Email successfully sent!');
        })
        .catch((error) => {
            console.error('Error sending email:', error);
            // Add any error handling logic here, e.g., showing an error message to the user
            alert('Error sending email. Please try again later.');
        });
    };
    return (
        <div className="wrapper">
        <div className="wrapper row4">
            <footer id="footer" className="hoc clear"> 
            {/*
                <div className="center btmspace-50">
                    <h6 className="heading">More Links</h6>
                    <nav style={{textAlign: "center"}}>
                    <ul className="nospace inline pushright uppercase">
                        <li><NavLink to="/"><i className="fas fa-lg fa-home"></i></NavLink></li>
                        <li><NavLink to="/about">About</NavLink></li>
                        <li><NavLink to="/contact">Contact</NavLink></li>
                        <li><a href="#">Terms</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">Cookies</a></li>
                        <li><a href="#">Disclaimer</a></li>
                    </ul>
                    </nav>
                </div>
                */}
                <hr className="btmspace-50" />
                <div className="group">
                    <div className="one_half first">
                    <h6 className="heading">Points of Contact</h6>
                    <ul className="nospace btmspace-30 linklist contact">
                        <li><i className="fas fa-map-marker-alt"></i>
                        <address style={{fontSize: "12px"}}>
                        275 North Elm St, Westfield, MA, 01085
                        </address>
                        </li>
                        <li style={{fontSize: "12px"}}>Phone: +1 (413) 737 3197</li>
                        <li style={{fontSize: "12px"}}>Email: precisionmfg2@gmail.com</li>
                    <li style={{fontSize: "12px"}}>Fax: +1 (413) 737 3198</li>
                    </ul>
                    {/* <ul className="faico clear">
                        <li><a className="faicon-facebook" href="#"><i className="fab fa-facebook"></i></a></li>
                        <li><a className="faicon-google-plus" href="#"><i className="fab fa-google-plus-g"></i></a></li>
                        <li><a className="faicon-linkedin" href="#"><i className="fab fa-linkedin"></i></a></li>
                        <li><a className="faicon-twitter" href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a className="faicon-vk" href="#"><i className="fab fa-vk"></i></a></li>
                    </ul> */}
                    </div>
                    <div className="one_half">
                    <h6 className="heading">Email Us</h6>
                    <p className="nospace btmspace-15">To contact us, please send us an email below or call our office number.</p>
                    <form onSubmit={handleSubmit}>
                        <fieldset>
                        <input className="btmspace-15"
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)} 
                            placeholder="Name"
                            required 
                        />
                        <input className="btmspace-15" 
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required 
                        />
                        <input className="btmspace-15" 
                            type="text" 
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Message"
                            required  
                        />
                        <button type="submit">Submit</button>
                        </fieldset>
                    </form>
                    </div>
                </div>
                </footer>
            </div>
            <div className="wrapper row5">
                <div id="copyright" className="hoc clear"> 
                <p className="fl_left">Copyright &copy; 2024 - All Rights Reserved - <a>Precision Manufacturing Ind., LLC</a></p>
                </div>
            </div>  
        </div>
        
    );
};

export default Footer;