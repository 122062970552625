import React from 'react';
import '../layout/styles/layout.css';

function GalleryPage() {
  return (
    <div className="GalleryPage">
      <div class="wrapper row3">
        <main class="hoc container clear"> 
          <div class="content"> 
            <div id="gallery">
              <figure>
                <header class="heading"></header>
                <ul class="nospace clear">
                  <li class="one_quarter first"><img src={require("../images/background_cover.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/shop_photo1.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/shop_photo2.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/shop_photo3.JPG")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/shop_photo4.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/shop_photo5.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/shop_photo6.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part22.JPG")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/shop_photo7.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part20.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part21.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part23.JPG")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/part5.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part6.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part7.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part8.JPG")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/part9.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part10.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part11.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part12.JPG")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/part13.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part14.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part15.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part16.JPG")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/part17.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part18.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part19.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part4.JPG")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/part1.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part2.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part3.JPG")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part24.jpg")} alt=""/></li>
                  <li class="one_quarter first"><img src={require("../images/part25.jpg")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part26.jpg")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part27.jpg")} alt=""/></li>
                  <li class="one_quarter"><img src={require("../images/part28.jpg")} alt=""/></li>
                </ul>
              </figure>
            </div>
          </div>
          <div class="clear"></div>
        </main>
      </div>
    </div>
  );
}

export default GalleryPage;