import React from 'react';

function AboutUsPage() {
  return (
    <div className="AboutUsPage">
      <div className="center" style={{marginTop: '30px', marginLeft: '5%', marginRight: '5%', marginBottom: '30px'}}>
        <h1 style={{fontSize: '24px'}}>Overview</h1>
        <p> 
          Precision Manufacturing was established in 2007. We make it easy to design and make new products, produce parts for existing products, make any kind of custom part,
          and reverse engineer replacement parts. Our goal is to provide our customers with the highest quality products at the best price. We continue to grow thanks to our customers'
          support and new demands of an ever-changing manufacturing world. 
        </p>
        <h2 style={{fontSize: '24px'}}>Facilities List</h2>
        <p>
          Our shop is equipped with the following equipment: <br/><br/>
          1  MATSUURA  # MC-800VG-II CNC VERTICAL  MACHINING CENTER W/TABLE                     SIZE 45"x20", TRAVELS: X-31.50", Y-20.07", Z-19.68", (40) ATC, #BT-40 SPINDLE,   40-12,000 RPM, 30HP, HIGH PRESSURE THRU-SPINDLE COOLANT SYSTEM, CHIP CONVEYOR & YASNAC J-300MB CNC CONTROL, S/N 000714151 6” HAAS ROTARY TABLE
          <br/>
          1  DOOSAN DNM500HS, 15K SPINDLE, 20” X 40” TRAVELS, 30 TOOL CHANGER
          <br/>
          1  MATSUURA ES450 HII-PCS 5 PALLET MACHINE 15K SPINDLE, 80 TOOL, 300MM TOMBSTONE
          <br/>
          1  MATSUURA ES450H, 2 PALLET MACHINE, 10K SPINDLE, 300MM TOMBSTONE
          <br/>
          1  STAR SB-16R TYPE E, 16MM SWISS LATHE WITH MILLING CAPABILITY.
          <br/>
          1  CINCOM CITIZEN B12 SWISS LATHE, 12MM BAR CAPABILITY.
          <br/>
          1  MORI SEIKI MV JUNIOR VERTICAL MACHINING CENTER 24” X 14” X 20” TRAVEL LINEAR GUIDE WAYS WITH HIGHER RAPID TRAVERSE POSITIONING YASNAC CONTROLS
          <br/>
          1  OKUMA & HOWA MILLAC 4VA VERTICAL MACHINING CENTER WITH 4TH AXIS/ FANUC CONTROL 28” X 18” X 24” TRAVEL BOX WAYS/ HI / LOW GEARED HEAD
          <br/>
          1  BRIDGEPORT MILLING MACHINE
          <br/>
          1  TAKISAWA TSL-D 14” SWING ENGINE LATHE
          <br/>
          1  SUNNEN 1800 HONING MACHINE
          <br/>
          1  HARDINGE CHUCKER LATHE
          <br/>
          1  KENT 6” X 18” SURFACE GRINDER WITH COOLANT
          <br/>
          1  TESA HITE MAGNA 400
          <br/>
          1 MITUTOYO PH3500 SHADOWGRAPH SURFACE COMPARATOR AND FULL
          <br/>
          INSPECTION EQUIPMENT
          <br/>
          DETAILED LIST AVAILABLE UPON REQUEST.
        </p>
      </div>
    </div>
  );
}

export default AboutUsPage;