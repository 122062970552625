import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactUsPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would handle the form submission, e.g., sending an email
    console.log('Email:', email);
    console.log('Message:', message);

    // Use Emailjs to send the email
    // Replace the placeholders with your own Emailjs credentials and template ID
    emailjs.send('service_bkefn5d', 'template_pf7t05o', {
      email: email,
      message: name + ": " + email + ': ' + message
    }, 'wp8-V6wzAlIvJAvMY')
      .then((response) => {
        console.log('Email sent successfully:', response.text);
        // Add any additional logic here, e.g., showing a success message to the user
        alert('Email successfully sent!');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // Add any error handling logic here, e.g., showing an error message to the user
        alert('Error sending email. Please try again later.');
      });
  };

  return (
    <div class="wrapper" style={{backgroundColor: "silver"}}>
      <article className="hoc container clear">
        <div className="ContactUsPage" style={{ textAlign: 'center', color: 'black' }}>
          <div className="center btmspace-80">
            <h4 className="heading underline font-x2" style={{color: "black"}}>Contact Us</h4>
          </div>
          <div class="contact-form">
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <input className="btmspace-15"
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)} 
                placeholder="Name"
                required 
                style={{ width: '50%' }}
              />
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                style={{ width: '50%' }}
              />
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
                required
                style={{ width: '50%'}}
              />
              <div style={{ marginTop: '10px' }}></div>
              <button type="submit">Send Email</button>
            </form>
          </div>
        </div>
      </article>
    </div>
  );
}

export default ContactUsPage;